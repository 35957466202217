<template>
  <div class="container">
    <table-page dataName="adverts"
                :search="search"
                ref="table"
                @on-success="fetchSuccess"
                :request="fetchAdvs">
      <template slot="button">
        <el-button type="primary"
                   class="mr-15"
                   @click="$refs.form.showModel()">新增广告</el-button>
      </template>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="广告名称">
      </el-table-column>
      <el-table-column label="广告区域"
                       show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.province}}{{scope.row.city}}{{scope.row.district}}{{scope.row.net}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary"
                     icon="el-icon-edit"
                     size="small"
                     @click="$refs.form.showModel(scope.row)"
                     circle></el-button>
          <el-button type="danger"
                     size="small"
                     icon="el-icon-delete"
                     @click="delAdv(scope.row)"
                     circle></el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetAdvert ref="form"
               @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import SetAdvert from './components/SetAdvert'
import { fetchAdvs, delAdv } from '@/api/market'
export default {
  name: 'Adv',
  data () {
    return {
      fetchAdvs,
      searchParams: {},
      search: [{ type: 'area' }]
    }
  },
  components: {
    TablePage,
    SetAdvert
  },
  methods: {
    exportEmployeeData () {
      window.open(this.exportUrl, '_blank')
    },
    delAdv (e) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delAdv({
          advert_ids: [e.id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      }).catch(() => {

      })
    },
    fetchSuccess (e) {
      this.exportUrl = e.export_url
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
