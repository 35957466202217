<template>
  <el-dialog :title="form.id?'修改广告':'新增广告'"
             width="60%"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible">
    <el-form ref="form"
             :rules="rules"
             label-position="top"
             :model="form">
      <el-form-item label="管辖区域">
        <Area @change="areaChange"
              ref="area"
              v-model="address" />
      </el-form-item>
      <el-form-item label="广告名称"
                    prop="name">
        <el-input v-model="form.name"
                  clearable
                  placeholder="请输入广告名称"></el-input>
      </el-form-item>
      <el-form-item label="活动图片"
                    prop="img_id">
        <ImgUpload v-model="form.img_id"
                   pixel="用于页面配图，建议使用640*200像素"
                   :fileList="imgList" />
      </el-form-item>

    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="resetForm('form')">重置</el-button>
      <el-button type="primary"
                 @click="submitForm('form')">{{form.id?'修改':'立即创建'}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Area from '@/components/Area'
import ImgUpload from '@/components/ImgUpload'
import { getImgId } from '@/utils'
import { setAdv } from '@/api/market'
export default {
  data () {
    return {
      address: {
        province: '',
        city: '',
        district: '',
        net: ''
      },
      form: {
        name: '',
        img_id: '',
        city: '',
        province: '',
        district: '',
        net: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入广告名称', trigger: 'blur' }
        ],
        img_id: [
          { required: true, message: '请上传广告图片', trigger: 'change' }
        ]
      },
      dialogVisible: false,
      imgList: []
    }
  },
  computed: {

  },
  components: {
    Area,
    ImgUpload
  },
  methods: {
    areaChange (address) {
      const { form } = this
      form.province = address.province
      form.city = address.city
      form.district = address.district
      form.net = address.net
    },
    showModel (formData) {
      this.dialogVisible = true
      if (formData) {
        this.address = {
          province: formData.province,
          city: formData.city,
          district: formData.district,
          net: formData.net
        }
        this.$nextTick(() => {
          const form = Object.assign({}, formData)
          form.advert_id = formData.id
          form.img_id = getImgId(formData.img_data)
          this.form = form
          this.imgList = [formData.img_data]
          this.$refs.form.resetFields()
        })
      } else {
        this.$nextTick(() => {
          this.resetForm()
        })
      }
    },
    submitForm (formName) {
      const { form } = this
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setAdv(form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    },
    resetForm () {
      this.$refs.area.reset()
      this.imgList = []
      this.$nextTick(() => {
        const { address } = this
        this.form = {
          net: address.net,
          city: address.city,
          province: address.province,
          district: address.district,
          name: '',
          img_id: ''
        }
        this.$refs.form.resetFields()
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
